import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, CardContent, CardMedia, IconButton, Link } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { getCountryFlag } from '@raas/shared/src/components/country-flag-dropdown/CountryFlagDropdown';
import Iconify from '@raas/shared/src/components/minimals/iconify';
import TextMaxLine from '@raas/shared/src/components/minimals/text-max-line';
import LegalProvisionContext from '@raas/shared/src/contexts/LegalProvisionContext';
import { useLocales } from '@raas/shared/src/locales';
import { PATH_DASHBOARD } from '@raas/shared/src/routes/paths';
import { NAV } from '@raas/shared/src/shared-config-global';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
export var NavCardItem = function (_a) {
    var current = _a.current, theme = _a.theme;
    var translate = useLocales().translate;
    var _b = useContext(LegalProvisionContext), clearLegalProvisionContext = _b.clearLegalProvisionContext, legalProvisionContext = _b.legalProvisionContext;
    var navigate = useNavigate();
    var handleReset = function () {
        clearLegalProvisionContext();
        navigate(PATH_DASHBOARD.overview.root);
    };
    return (_jsx(Link, { href: legalProvisionContext ? "".concat(PATH_DASHBOARD.legalProvision.root, "/").concat(current === null || current === void 0 ? void 0 : current.id, "/details") : '', children: _jsxs(Card, { sx: {
                display: 'flex',
                width: NAV.W_DASHBOARD,
                height: 61,
                borderRadius: 0,
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            }, children: [(current === null || current === void 0 ? void 0 : current.abbreviation) && (_jsx(CardMedia, { component: "div", sx: {
                        width: 90,
                        objectPosition: 'center',
                        clipPath: 'polygon(0 0, 100% 0, 80% 100%, 0 100%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }, children: getCountryFlag(current === null || current === void 0 ? void 0 : current.countryFlag, { height: '100' }) })), _jsx(Box, { sx: { display: 'flex', flexDirection: 'row', flexGrow: 1 }, children: _jsx(CardContent, { sx: {
                            flex: '1 0 auto',
                            padding: 0,
                            pl: 1,
                            width: 'fit-content',
                            flexDirection: 'row',
                            alignSelf: 'center',
                            '&:last-child': { pb: 0 },
                        }, children: current && (_jsxs(_Fragment, { children: [_jsx(TextMaxLine, { line: 2, sx: { fontWeight: 'bold', maxWidth: '175px' }, variant: "body2", children: current.abbreviation }), _jsx(TextMaxLine, { line: 1, color: "text.secondary", variant: "caption", children: "".concat(translate('landingPage.legislationItems.version'), " ").concat(current.versionName) })] })) }) }), _jsx(IconButton, { "aria-label": "delete", sx: { position: 'absolute', right: 0, top: 0 }, onClick: handleReset, children: _jsx(Iconify, { icon: "eva:close-fill" }) })] }) }));
};
