var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { NAV } from '@raas/shared/src/shared-config-global';
import { useSettingsContext } from '@raas/shared/src/components/minimals/settings';
function Copyright() {
    return (_jsxs(Typography, { variant: "body2", color: "text.secondary", children: ["Copyright \u00A9", _jsx(Link, { sx: { px: '4px' }, color: "inherit", href: "https://www.kontrol.tech/", children: "Kontrol.tech" }), new Date().getFullYear(), "."] }));
}
export default function Footer() {
    var theme = useTheme();
    var themeLayout = useSettingsContext().themeLayout;
    var isNavMini = themeLayout === 'mini';
    return (_jsx(Box, { component: "footer", sx: __assign(__assign({ py: 3, px: 2, ml: 'auto', mt: 'auto', width: "calc(100% - ".concat(NAV.W_DASHBOARD + 1, "px)") }, (isNavMini && {
            width: "calc(100% - ".concat(NAV.W_DASHBOARD_MINI + 1, "px)"),
        })), { backgroundColor: theme.palette.background.default }), children: _jsxs(Container, { children: [_jsxs(Stack, { flexGrow: 1, direction: "row", alignItems: "center", justifyContent: "flex-end", spacing: { xs: 1.5, sm: 2.5, lg: 3.5 }, children: [_jsx(Typography, { variant: "body1", children: _jsx(Link, { color: "inherit", href: "https://www.kontrol.tech/terms-and-conditions/", children: "Legal Notice" }) }), _jsx(Typography, { variant: "body1", children: _jsx(Link, { color: "inherit", href: "https://www.kontrol.tech/legal/", children: "Impressum" }) })] }), _jsx(Copyright, {})] }) }));
}
